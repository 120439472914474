import {importAll} from "./helpers/importAll";

importAll(require.context('../img', false, /\.(png|jpe?g|svg)$/));
importAll(require.context('../img/core', false, /\.(png|jpe?g|svg)$/));
importAll(require.context('../../../components/core', true, /\.(js)$/));


importAll(require.context('./helpers', true, /\.(js)$/));

importAll(require.context('./../../../components/blocks', true, /\.(js)$/));
// import './../../../components/blocks/reviews-slider/reviews-slider'
// import './../../../components/blocks/ingredients-slider/ingredients-slider'

importAll(require.context('./../../../components/woocommerce', true, /\.(js)$/));
// import './../../../components/woocommerce/single-product'
// import './../../../components/woocommerce/checkout'
