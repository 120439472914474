class Checkout {
    constructor() {
        const fields = document.querySelectorAll('.woocommerce-checkout-fields .form-row, .woocommerce-additional-fields .form-row')
        const shipToDiffAddrInput = document.querySelector('#ship-to-different-address-checkbox')

        fields.forEach(field => {
            const input = field.querySelector('input, textarea')

            if (input) {
                if (input.value) {
                    field.classList.add('is-active')
                }

                input.addEventListener('focusin', () => {
                    field.classList.add('is-active')
                })
                input.addEventListener('focusout', () => {
                    if (!input.value) {
                        field.classList.remove('is-active')
                    }
                })
            }
        })

        if (shipToDiffAddrInput) {
            shipToDiffAddrInput.addEventListener('change', () => {
                console.log(shipToDiffAddrInput.checked)
                shipToDiffAddrInput.closest('.woocommerce-checkout-fields').classList.toggle('is-hidden')
            })
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('body').classList.contains('woocommerce-checkout')) {
        new Checkout();
    }
});