import Swiper from "swiper";
import {Navigation, Pagination, Grid} from "swiper/modules";

class IngredientsSlider {
    constructor(element) {
        this.el = element
        this.sliderEl = this.el.querySelector('.js-ingredients-slider__slider')

        this.swiper = new Swiper(this.sliderEl, {
            modules: [Navigation, Pagination, Grid],
            grid: {
                rows: 3,
                fill: 'row'
            },
            spaceBetween: 36,
            pagination: {
                el: ".js-ingredients-slider__pagination",
                bulletClass: this.el.dataset.paginationBulletClass ?? '',
                bulletActiveClass: 'is-active',
            },
            breakpoints: {
                768: {
                    grid: {
                        rows: 1
                    },
                    slidesPerView: 3
                },
                992: {
                    grid: {
                        rows: 1
                    },
                    slidesPerView: 3,
                    spaceBetween: 60
                }
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const ingredientsSlider = document.querySelector('.js-ingredients-slider');
    if (ingredientsSlider) {
        new IngredientsSlider(ingredientsSlider);
    }
});