class Header {
    constructor(el) {
        this.el = el
        this.toggles = this.el.querySelectorAll('.js-header__toggle');

        this.toggles.forEach(toggle => {
            toggle.addEventListener('click', (e) => {
                this.toggleMenu()
            })
        })
    }

    toggleMenu() {
        this.el.classList.toggle('is-active')
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector('.js-header')
    if (el) {
        new Header(el);
    }
});
